import { createBreakpoints } from '@chakra-ui/theme-tools';

export const breakpoints = createBreakpoints({
  xs: '375px',
  sm: '480px',
  md: '768px',
  lg: '960px',
  xl: '1200px',
  '2xl': '1920px',
  '3xl': '2560px',
});
